import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { CenterSection } from "../center-section";

export const Footer: React.FC = () => (
  <CenterSection>
    <div className="flex flex-col justify-start mt-32 py-5 border-t border-white sm:flex-row sm:justify-between">
      <div className="mb-2 sm:m-0">
        <GatsbyLink to="/impressum" className="hover:underline">
          Impressum
        </GatsbyLink>
      </div>
      <div>&copy; {new Date().getFullYear()} Fil der Protagonist</div>
    </div>
  </CenterSection>
);
