import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

type MetaTagProps = JSX.IntrinsicElements["meta"];

type Props = {
  lang: string;
  title: string;
  description: string;
  author: string;
  image?: { width: number; height: number; src: string };
  meta?: MetaTagProps[];
};

const GET_SITE_META = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        lang
        siteUrl
      }
    }
  }
`;

export const SEO: React.FC<Partial<Props>> = (pageProps) => {
  const {
    site: { siteMetadata: siteProps },
  } = useStaticQuery<{ site: { siteMetadata: Props & { siteUrl: string } } }>(
    GET_SITE_META
  );
  const { pathname } = useLocation();

  const props: Props = {
    ...siteProps,
    ...pageProps,
  };
  if (pageProps.title) props.title = `${pageProps.title} | ${siteProps.title}`;

  const { title, description, author, lang, image, meta } = props;

  const defaultMeta: MetaTagProps[] = [
    { name: `description`, content: description },
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:type", content: "website" },
    {
      property: "og:url",
      content: `${siteProps.siteUrl}${pathname}`,
    },
    { name: "twitter:card", content: "summary" },
    { name: "twitter:creator", content: author },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: description },
  ];

  if (image)
    defaultMeta.push(
      {
        property: "og:image:width",
        content: String(image.width),
      },
      {
        property: "og:image:height",
        content: String(image.height),
      },
      {
        property: "og:image",
        content: `${siteProps.siteUrl}${image.src}`,
      }
    );

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      meta={meta ? [...defaultMeta, ...meta] : defaultMeta}
    />
  );
};
