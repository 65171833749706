import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { CenterSection } from "../center-section";

import styles from "./site-header.module.css";

export const SiteHeader: React.FC = ({ children }) => (
  <CenterSection>
    <div
      className={`${styles.wrapper} site-header font-heading my-16 uppercase leading-tight`}
    >
      <GatsbyLink to="/" className="focus:outline-none">
        Fil der
        <br />
        Protagonist
      </GatsbyLink>
    </div>
  </CenterSection>
);
