import React from "react";
import cs from "classnames";

export const CenterSection: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div className={cs("relative px-5 max-w-6xl mx-auto md:px-8", className)}>
    {children}
  </div>
);
