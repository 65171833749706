import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import cx from "classnames";
import {
  getBackgroundAnimation,
  createBackgroundAnimation,
  BackgroundAnimation,
} from "./background-animation";
import { Footer } from "./footer";

import { SiteHeader } from "../site-header";

export const Layout: React.FC = ({ children }) => {
  const [animReady, setAnimReady] = React.useState(!!getBackgroundAnimation());

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fixed(width: 2048, height: 2048) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    function handleAnimation(animation: BackgroundAnimation) {
      animation.attach(wrapperRef.current!);
    }

    const animation = getBackgroundAnimation();
    if (animation) {
      handleAnimation(animation);
      return;
    }

    let mounted = true;

    createBackgroundAnimation(data.file.childImageSharp.fixed.src).then(
      (animation) => {
        if (!mounted) return;
        handleAnimation(animation);
        setAnimReady(true);
      }
    );

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <div
        className={cx(
          "fixed inset-0 pointer-events-none transition",
          animReady ? "opacity-25" : "opacity-0"
        )}
        style={{ transitionDuration: "2s" }}
        ref={wrapperRef}
      />
      <div className="relative">
        <SiteHeader />
        {children}
      </div>
      <Footer />
    </>
  );
};
