import { WebGLRenderer } from "three/src/renderers/WebGLRenderer";
import { Scene } from "three/src/scenes/Scene";
import { OrthographicCamera } from "three/src/cameras/OrthographicCamera";
import { PlaneGeometry } from "three/src/geometries/PlaneGeometry";
import { ShaderMaterial } from "three/src/materials/ShaderMaterial";
import { Mesh } from "three/src/objects/Mesh";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { Clock } from "three/src/core/Clock";
import { MirroredRepeatWrapping } from "three/src/constants";

import fragmentShader from "./shader.frag";
import vertexShader from "./shader.vert";

export type BackgroundAnimation = {
  attach(element: HTMLElement): void;
  dispose(): void;
};

let backgroundAnimation: BackgroundAnimation | null = null;
export const getBackgroundAnimation = () => backgroundAnimation;

export function createBackgroundAnimation(
  imageUrl: string
): Promise<BackgroundAnimation> {
  return new Promise((resolve) => {
    const onLoaded = () =>
      resolve(
        (backgroundAnimation = {
          attach(element: HTMLDivElement) {
            element.appendChild(renderer.domElement);
          },
          dispose() {
            renderer.domElement.remove();
            renderer.dispose();
            window.removeEventListener("resize", updateSize);
            running = false;
          },
        })
      );

    const scene = new Scene();
    const timeUniform = { value: 0 };
    const diameterUniform = { value: 0 };
    const texture = new TextureLoader().load(imageUrl, onLoaded);
    texture.wrapS = texture.wrapT = MirroredRepeatWrapping;

    scene.add(
      new Mesh(
        new PlaneGeometry(1, 1),
        new ShaderMaterial({
          uniforms: {
            time: timeUniform,
            diameter: diameterUniform,
            colorTexture: { value: texture },
          },
          fragmentShader,
          vertexShader,
        })
      )
    );

    const cam = new OrthographicCamera(-0.5, 0.5, 0.5, -0.5);
    cam.position.z = 1;

    const renderer = new WebGLRenderer();
    const clock = new Clock();

    function updateSize() {
      const { innerWidth: w, innerHeight: h } = window;
      const ratio = w / h;
      if (ratio >= 1) {
        cam.left = -0.5;
        cam.right = 0.5;
        const offset = 0.5 / ratio;
        cam.top = offset;
        cam.bottom = -offset;
      } else {
        cam.top = 0.5;
        cam.bottom = -0.5;
        const offset = ratio / 2;
        cam.left = -offset;
        cam.right = offset;
      }
      cam.updateProjectionMatrix();
      renderer.setSize(w, h);
      diameterUniform.value = Math.sqrt(w * w + h * h);
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    let running = true;
    function render() {
      timeUniform.value += clock.getDelta();

      renderer.render(scene, cam);
      if (running) requestAnimationFrame(render);
    }
    render();
  });
}
